<template>
	<!-- 后台主页的框架界面----- :style="backgroundDiv"-->
	<div class="sum" >
		<!-- 头部 -->
		<!-- <el-header>
			<HeaderView/>
		</el-header> -->
<!-- 		<el-container>
			
			<HeaderView/>
			
			<router-view style="flex: 1; margin: auto;"></router-view>
			
			<FooterView/>
		  
		</el-container> -->
	
		<el-container>
			<HeaderView :activeIndex="activeIndex"/>
			
			<!-- <div style="display: flex; background-color: #161622;">
					    
			    <router-view style="flex: 1;"></router-view>
			</div> -->
			
			
			<router-view class="me-container"></router-view>
			
			<!-- <FooterView /> -->
			<el-footer style="background-color: #282424; display: flex;height: 28vh; width:100%; align-item:centerl">
				<div style="width: 50%; margin: auto;margin-top: 20px; color: #f1f1f1;">
					<div style="width: 100%; margin: auto; color: #f1f1f1; display:flex">
						<div style="width: 70%; margin-left:0%; display:flex; color: #b1b1b1; font-size: 20px;">
							<div style="width: 30%; margin-left:0%;">
								关于我们<br>
								<div style="height: 10px;"></div>
								<div class="foot-title">
									<p style="font-size: 15px;" @click="toAbout1">公司详情</p>
								</div>
							</div>
							<div style="width: 30%;">
								产品详情<br>
								<div style="height: 10px;"></div>
								<div class="foot-title">
									<p style="font-size: 15px;" @click="toP1">产品中心</p>
								</div>
								<div style="height: 10px;"></div>
								<div class="foot-title">
									<p style="font-size: 15px;" @click="toF1">飞控平台</p>
								</div>
								<div style="height: 10px;"></div>
								<div class="foot-title">
									<p style="font-size: 15px;" @click="toF2">飞控系统</p>
								</div>
								
							</div>
							<div>
								服务与支持<br>
								<div>
									<div style="height: 5px;"></div>
									<img src="../assets/img/chat.jpg" style="height: 100px; width: 100px; display: block; margin: auto;" alt="img lost...">
								</div>
							</div>
						</div>
						<div style="width: 30%; margin-right: 0%; ">
							<img src="../assets/img/LOGO_black.png" style="max-width: 100%; max-height: 100%; display: block; margin: auto;" alt="img lost...">
						</div>
					</div>
					
					<div>
						<hr style="background-color:white">
					</div>
					<div style="width: 100%; margin: auto; color: #f1f1f1; display:flex">
						<div style="width: 5%;"></div>
						<a href="https://beian.miit.gov.cn" target="_blank" style="color: #f1f1f1;text-decoration: none;">豫ICP备2023006306号</a> 
						<div style="width: 10%;"></div>
						<img src="../assets/img/beian.png" style="float:left;"/>
						<a href="https://www.beian.gov.cn/" target="_blank" style="color: #f1f1f1;text-decoration: none;">豫公网安备 41019702003288号</a>
					</div>
				</div>
			</el-footer>
		</el-container>
		
	</div>
	<el-backtop :right="100" :bottom="100" />
</template>

<script>
	import HeaderView from '@/components/HeaderView.vue'
	import FooterView from '@/components/FooterView.vue'
	export default {
	  name: "Layout",
	  data() {
	      return {
			  screenWidth: 0,
			  isPC: this.isPCNot(),
	        // 背景图片
	        backgroundDiv: {
	          backgroundImage: 'url(' + require('@/assets/img/bg.jpg') + ')',
	          backgroundRepeat: 'no-repeat',
	          backgroundSize: '100% 100%',
	        }
	      }
	  },
	  watch: {
	          screenWidth: {
	              handler: function (val, oldVal) {
	                  if (val < 750) {
	                      this.isPC = false
	                  } else {
	                      this.isPC = true
	                  }
	                  console.log(this.isPC);
	              },
	          },
	  
	      },
	      mounted() {
	          this.screenWidth = document.body.clientWidth
	          window.onresize = () => {
	              return (() => {
	                  this.screenWidth = document.body.clientWidth
	              })()
	          }
	      },
	      methods: {
	          isPCNot() {
	              var userAgentInfo = navigator.userAgent;
	              var Agents = ["Android", "iPhone",
	                  "SymbianOS", "Windows Phone",
	                  "iPad", "iPod"];
	              var flag = true;
	              for (var v = 0; v < Agents.length; v++) {
	                  if (userAgentInfo.indexOf(Agents[v]) > 0) {
	                      flag = false;
	                      break;
	                  }
	              }
	              return flag;
	          },
			  toAbout1: function(){
			  	this.$router.replace('/about');
			  },
			  toP1: function(){
			  	this.$router.replace('/products');
			  },
			  toF1: function(){
			  	this.$router.replace('/flyPlatform');
			  },
			  toF2: function(){
			  	this.$router.replace('/flySystem');
			  },
	      },
	  components: {
	    HeaderView,
		FooterView
	  }
	}
</script>

<style>
	.sum{
		height: 100%;
		width: 100%;
		min-width: 100vw;
		top: 0;
		left: 0;
		position: relative;
	}
	.el-container{
		min-width: 100%;
		margin: auto;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.me-container{
	  /* margin: 60px 0; */
	  left: 0;
	  width: 100%;
	  padding-top: 60px;
	}
	@media screen and (max-width: 750px) {
	    .body {
	        width: 90%;
	        margin: 0 auto;
	        background-color: #f1f1f1;
	    }
	}
	
	@media screen and (min-width: 750px) {
	    .body {
	        margin: 0 auto;
	        width: 50%;
	        background-color: #f1f1f1;
	    }
	}
	.foot-title:hover{
		color: #f1f1f1;
		cursor: pointer;
	}
</style>