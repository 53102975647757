<template>
	<!-- <el-footer class="el-footer"> -->
<!-- 		<font size="25" color="black">河南智茂电子科技有限公司</font> -->
		<!-- <div class="foot">
			
				<font size="25" color="white">河南智茂电子科技有限公司</font>
			
			
		</div> -->
		<!-- <footer>
			heiheihei
		</footer> -->
	<!-- </el-footer> -->
	
</template>

<script>
	export default {
		name: "FooterView",
		data(){
			return{
				// User: {}
			}
		},
		created(){
			// var UserStr = sessionStorage.getItem('user')
			// this.User = JSON.parse(UserStr)
		}
	}
</script>

<style>
	/* .el-footer {
	  min-width: 100%;
	  box-shadow: 0 -2px 3px hsla(0, 0%, 7%, .1), 0 0 0 1px hsla(0, 0%, 7%, .1);
	  position: absolute;
	  bottom: 0;
	  left: 0;
	  z-index: 1024;
	}
	
	.foot{
		text-align: center;
		line-height: 60px;
		font-family: 'Open Sans', sans-serif;
		font-size: 18px;
		background-color: black;
	}
	.me-login-design-color {
	  color: #5FB878 !important;
	} */
	
	/* .foot{
		text-align: center;
		line-height: 60px;
		min-width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
	} */
	
</style>