<template>


	<div class="me-product" style="width: 100%;padding-top: 60px ;background-color: white; ">
		<div class="me-product-title" style="width: 100%;font-weight:550;color: #f6fffe; font-size: 50px; text-align: center; margin:auto; background-color: #282424;" >
			产品中心
		</div>
		
		<div class="me-product-content" style=" width: 55%; margin:auto;">
			<div style="height: 5px;"></div>
			<div style="width: 80%;font-weight:500;color: #f6fffe; font-size: 50px; text-align: center; margin:auto; background-color: #a19f9f;">
				无人机开发平台
				<!-- <p style="font-weight:520; font-size: 40px;letter-spacing:4px;margin:auto;">无人机开发平台：</p> -->
			</div>
			<div style="height: 5px;"></div>
			<!-- 产品预览部分 -->
			<div style="width: 100%;">
				<div class="f1" id="title1" style="width: 80%; margin:auto; display:flex"  @click="drawer_101 = true">
					<div style="width: 50%; margin-left: 0%;">
						<img src="../assets/img/f1.jpg" style="max-width: 100%; max-height: 100%; display: block; margin: auto;" alt="img lost...">
					</div>
					<div style="width: 50%; margin-right:0%; text-align: center; background-color: #f4f4f4; ">
						
						<p style="margin-top:30%;font-size: 30px;color: #282424;">AI智能识别无人机</p>
					</div>
				</div>
			</div>
			
			<div style="height: 5px;"></div>
			<div style="width: 100%;">
				<div class="f1" style="width: 80%; margin: auto; display:flex" @click="drawer_102 = true">
					<div style="width: 50%; margin-left: 0%;">
						<img src="../assets/img/f02.jpg" style="max-width: 100%; max-height: 100%; display: block; margin: auto;" alt="img lost...">
					</div>
					<div style="width: 50%; margin-right:0%; text-align: center; background-color: #f4f4f4;">
						<p style="margin-top:30%;font-size: 30px;color: #282424;">竞赛/科研-双目视觉无人机</p>
					</div>
				</div>
			</div>
			
			<div style="height: 5px;"></div>
			<div style="width: 100%;">
				<div class="f1" style="width: 80%; margin: auto; display:flex" @click="drawer_103 = true">
					<div style="width: 50%; margin-left: 0%;">
						<img src="../assets/img/f03.jpg" style="max-width: 100%; max-height: 100%; display: block; margin: auto;" alt="img lost...">
					</div>
					<div style="width: 50%; margin-right:0%; text-align: center; background-color: #f4f4f4;">
						<p style="margin-top:30%;font-size: 30px;color: #282424;">竞赛/科研-定位与导航无人机</p>
					</div>
				</div>
			</div>
			
			<div style="height: 5px;"></div>
			<div style="width: 100%;">
				<div class="f1" style="width: 80%; margin: auto; display:flex" @click="drawer_104 = true">
					<div style="width: 50%; margin-left: 0%;">
						<img src="../assets/img/f04.jpg" style="max-width: 100%; max-height: 100%; display: block; margin: auto;" alt="img lost...">
					</div>
					<div style="width: 50%; margin-right:0%; text-align: center; background-color: #f4f4f4;">
						<p style="margin-top:20%;font-size: 30px;color: #282424;">AI智能识别复合翼无人机</p>
					</div>
				</div>
			</div>
			
			<div style="height: 5px;"></div>
			<div style="width: 80%;font-weight:500;color: #f6fffe; font-size: 50px; text-align: center; margin:auto; background-color: #a19f9f;">
				实验箱
				<!-- <p style="font-weight:520; font-size: 40px;letter-spacing:4px;margin:auto;">无人机开发平台：</p> -->
			</div>
			
			<div style="height: 5px;"></div>
			<div style="width: 100%;">
				<div class="f1" style="width: 80%; margin: auto; display:flex" @click="drawer_201 = true">
					<div style="width: 50%; margin-left: 0%;">
						<img src="../assets/img/TestBox1.png" style="max-width: 100%; max-height: 100%; display: block; margin: auto;" alt="img lost...">
					</div>
					<div style="width: 50%; margin-right:0%; text-align: center; background-color: #f4f4f4;">
						<p style="margin-top:30%;font-size: 30px;color: #282424;">无人机控制系统实训平台</p>
					</div>
				</div>
			</div>
			
			<div style="height: 5px;"></div>
			<div style="width: 100%;">
				<div class="f1" style="width: 80%; margin: auto; display:flex" @click="drawer_202 = true">
					<div style="width: 50%; margin-left: 0%;">
						<img src="../assets/img/TestBox2.png" style="max-width: 100%; max-height: 100%; display: block; margin: auto;" alt="img lost...">
					</div>
					<div style="width: 50%; margin-right:0%; text-align: center; background-color: #f4f4f4;">
						<p style="margin-top:30%;font-size: 30px;color: #282424;">无人机综合实训平台</p>
					</div>
				</div>
			</div>
			<br>
		</div>
	</div>
	
	<el-drawer v-model="drawer_101" size="60%" title="I am the title" :with-header="false" direction="btt">
	  <div style="width: 45%; margin: auto;">
		  <p style="color: #0bf1f4; font-size: 25px;">功能:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;支持自稳模式、定高模式、定点模式、智能返航模式、自动模式等</p>
		  <p style="font-size: 20px;">&emsp;&emsp;计算机视觉、神经网络与深度学习、AI智能识别等</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">飞控系统:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;商业级飞行控制器，商业级飞行控制系统，行业领先的控制算法，具有强大的传感器补偿算法、过滤和调整能力</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">AI算力:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;高达100TOPS</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">吊舱:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;支持单光、双光、三光吊舱,热成像、激光夜视等</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">应用方向:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;城市管理、农林监测、应急救援、无人侦察、科学研究、教育训练等领域</p><br>
	  </div>
	</el-drawer>
	<el-drawer v-model="drawer_102" size="60%" title="I am the title" :with-header="false" direction="btt">
	  <div style="width: 45%; margin: auto;">
		  <p style="color: #0bf1f4; font-size: 25px;">功能:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;自稳模式、定高模式、定点模式、智能返航模式、自动模式等</p>
		  <p style="font-size: 20px;">&emsp;&emsp;计算机视觉、神经网络与深度学习、AI智能识别等</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">飞行控制器:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;支持STM32F4、国产AT32F435处理器，陀螺仪、气压计、地磁计，扩展接口</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">飞控系统:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;基于国产实时操作系统RTT，全开源，支持MP地面站</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">应用方向:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;《飞行控制系统》、《无人机导航定位技术》、《嵌入式系统开发》、《图像处理》、《计算机视觉》等课程实践内容，无人机竞赛平台，支撑一流专业建设</p><br>
	  </div>
	</el-drawer>
	<el-drawer v-model="drawer_103" size="60%" title="I am the title" :with-header="false" direction="btt">
	  <div style="width: 45%; margin: auto;">
		  <p style="color: #0bf1f4; font-size: 25px;">功能:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;自稳模式、定高模式、定点模式、智能返航模式、自动模式等</p>
		  <p style="font-size: 20px;">&emsp;&emsp;实时建图导航，自主路径规划，自主避障、计算机视觉、AI智能识别等</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">飞行控制器:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;支持STM32F4、国产AT32F435处理器，陀螺仪、气压计、地磁计，扩展接口</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">飞控系统:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;基于国产实时操作系统RTT，全开源，支持MP地面站</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">应用方向:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;《飞行控制系统》、《无人机导航定位技术》、《嵌入式系统开发》、《图像处理》、《计算机视觉》等课程实践内容，无人机竞赛平台，支撑一流专业建设</p><br>
	  </div>
	</el-drawer>
	<el-drawer v-model="drawer_104" size="60%" title="I am the title" :with-header="false" direction="btt">
	  <div style="width: 45%; margin: auto;">
		  <p style="color: #0bf1f4; font-size: 25px;">功能:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;垂直起降、支持自稳模式、定高模式、定点模式、智能返航模式、自动模式等</p>
		  <p style="font-size: 20px;">&emsp;&emsp;计算机视觉、神经网络与深度学习、AI智能识别等</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">飞控系统:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;商业级飞行控制器，商业级飞行控制系统，行业领先的控制算法，具有强大的传感器补偿算法、过滤和调整能力</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">AI算力:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;高达100TOPS</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">吊舱:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;支持单光、双光、三光吊舱,热成像、激光夜视等</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">应用方向:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;城市管理、农林监测、应急救援、无人侦察、科学研究、教育训练等领域</p><br>
	  </div>
	</el-drawer>
	
	<el-drawer v-model="drawer_201" size="50%" title="I am the title" :with-header="false" direction="btt">
	  <div style="width: 45%; margin: auto;">
		  <p style="color: #0bf1f4; font-size: 25px;">系统组成:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;处理器STM32F4，陀螺仪，气压计，地磁计，GPS，超声波，激光，显示模块，通信模块，电机，电调，遥控器等</p>
		  <p style="color: #0bf1f4; font-size: 25px;">实践内容:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;单片机实验、传感器实验、控制实验、算法实验等</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">应用方向:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;《单片机开发》、《嵌入式系统开发》、《飞行控制系统原理》、《自动控制》、《传感器技术与应用》、《无人机测控系统》等课程的实验内容，可完成专业课程设计、专业实训、毕业设计，支撑一流专业建设</p><br>
	  </div>
	</el-drawer>
	<el-drawer v-model="drawer_202" size="50%" title="I am the title" :with-header="false" direction="btt">
	  <div style="width: 45%; margin: auto;">
		  <p style="color: #0bf1f4; font-size: 25px;">系统组成:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;处理器STM32F4，陀螺仪，气压计，地磁计，显示模块，通信模块，遥控器，无人机调试与飞行平台</p>
		  <p style="color: #0bf1f4; font-size: 25px;">实践内容:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;单片机实验、传感器实验、控制实验、算法实验、飞行控制系统实验</p><br>
		  <p style="color: #0bf1f4; font-size: 25px;">应用方向:</p><br>
		  <p style="font-size: 20px;">&emsp;&emsp;《单片机开发》、《嵌入式系统开发》、《飞行控制系统原理》、《自动控制》、《传感器技术与应用》、《无人机测控系统》等课程的实验内容，可完成专业课程设计、专业实训、毕业设计，支撑一流专业建设</p><br>
	  </div>
	</el-drawer>
	
</template>

<script>
	// import request from "@/utils/request"
	import { getCurrentInstance } from "vue";
	import { ref } from 'vue'
	const drawer_101 = ref(false)
	const drawer_102 = ref(false)
	const drawer_103 = ref(false)
	const drawer_104 = ref(false)
	const drawer_201 = ref(false)
	const drawer_202 = ref(false)
	export default {
		name: "ProductsPage",
		data(){
			return{
				// User: {}
				drawer_101,
				drawer_102,
				drawer_103,
				drawer_104,
				drawer_201,
				drawer_202,
			}
		},
		created(){
			//const PageId = getCurrentInstance()?.appContext.config.globalProperties.$PageId;
			console.log(this.$PageId);//控制台可以看到输出了10
			// var UserStr = sessionStorage.getItem('user')
			// this.User = JSON.parse(UserStr)
		},
		methods:{
			onTileClick: function(){
				var content = document.getElementById("content1");
				content.style.height = content.offsetHeight===150?0+'px':150+'px';
			},
			onTileClick_01: function(){
				var content = document.getElementById("content_01");
				content.style.height = content.offsetHeight===150?0+'px':150+'px';
			},
			onTileClick_02: function(){
				var content = document.getElementById("content_02");
				content.style.height = content.offsetHeight===150?0+'px':150+'px';
			}
		}

	}
</script>

<style>
	/* .el-main{
		height: 100%;
		overflow: hidden;
		width: 100%;
		background-color: aquamarine;
	} */
	.f1{
		transition: all 0.4s;
	}
	.f1:hover{
		cursor: pointer;
		box-shadow: 4px 4px 2px #b5b5b5;
		transform: translate(0, -4px);
	}
	
</style>