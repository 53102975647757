<template>
	
	<div class="me-product" style="width: 100%;padding-top: 60px ;background-color: white; ">
		<div class="me-product-title" style="width: 100%;font-weight:550;color: #f6fffe; font-size: 50px; text-align: center; margin:auto; background-color: black;" >
			关于我们
		</div>
		<div style="height: 15px;"></div>
		<div class="me-product-content" style=" width: 55%; margin:auto; background-color: #fefcfe;">
			<p style="width: 100%; margin: auto; font-size: 25px;line-height:50px;">
				&emsp;&emsp;河南智茂电子科技有限公司是郑州市科技型企业，公司位于郑州市高新区企业公园，
				专注于复合翼、旋翼无人机研发、生产、销售及服务，致力于为客户提供智能化、平台化、工具化的无人机系统。
			</p>
			<p style="width: 100%; margin: auto; font-size: 25px;line-height:50px;">
				&emsp;&emsp;技术方向：智能控制、AI智能识别、计算机视觉、定位与导航。
			</p>
			<p style="width: 100%; margin: auto; font-size: 25px;line-height:50px;">
				&emsp;&emsp;研发产品：高性能飞行控制器、飞行控制系统、高性能无人机智能数据处理平台、
				AI智能识别无人机平台，AI智能识别复合翼无人机平台、无人机综合实训平台、双目视觉无人机、定位与导航无人机等。
			</p>
			<p style="width: 100%; margin: auto; font-size: 25px;line-height:50px;">
				&emsp;&emsp;产品应用方向：复合翼及旋翼无人机AI智能识别、无人机专业实验室建设、竞赛无人机平台、科研无人机平台。
			</p>
			<p style="width: 100%; margin: auto; font-size: 25px;line-height:50px;">
				&emsp;&emsp;致力于无人机更智能、更高效！<br>
				&emsp;&emsp;价 值 观：以客户为中心，以奋斗着为本。<br>
				&emsp;&emsp;经营理念：专注、创新、价值、服务。<br>
				&emsp;&emsp;企业文化：自由与责任。<br>
				&emsp;&emsp;公司名称：河南智茂电子科技有限公司<br>
				&emsp;&emsp;公司地址：郑州市高新区企业公园29号楼4单元407 <br>
				&emsp;&emsp;联 系 人：朱经理 <br>
				&emsp;&emsp;联系方式：15981986662（微信同号）<br>
			</p>
			<br>
		</div>
	</div>
</template>

<script>
	// import request from "@/utils/request"
	export default {
		name: "AboutPage",
		data(){
			return{
				// User: {}
			}
		},
		created(){
			// var UserStr = sessionStorage.getItem('user')
			// this.User = JSON.parse(UserStr)
		}
	}
</script>

<style>
	.el-main{
		/* padding-top: 60px; */
		height: 100%;
		/* overflow: hidden; */
	}
</style>