<template>
	<!-- <div style="height: 60px; line-height: 60px; border-bottom: 1px solid #ccc; display: flex; background-color:white;"> -->
		
		<!-- <div style="margin-left: 25%;">
			<router-link to="/" class="me-title">
				<img src="../assets/img/logo.png" />
			</router-link>
		</div> -->
		
		<!-- <div style="margin-left: 20%;" >
			<button class="button1" index = "home" @click="toHome">首页</button>
		</div>	
		<div >
			<button class="button1" index = "products" @click="toProducts">产品中心</button>
		</div> 
		<div>
			<button class="button1" index = "about" @click="toAbout">关于我们</button>
		</div> -->
		<!-- <el-menu :router=true menu-trigger="click" active-text-color="#ff0000" :default-active="activeIndex"
		         mode="horizontal" style="margin-left: 20%; width: 500px;">
		  <el-menu-item index="/">首页</el-menu-item>
		  <el-menu-item index="/products">产品中心</el-menu-item>
		  <el-menu-item index="/about">关于我们</el-menu-item>
				
		</el-menu> -->

	<!-- </div> -->
	<!-- <div>
		<el-header class="me-area" style="background-color: white;">
			<el-row class="me-header">
			
			  <el-col :span="4" class="me-header-left">
			    <router-link to="/" class="me-title">
			      <img src="../assets/img/logo.png" />
			    </router-link>
			  </el-col>
			
			  <el-col v-if="!simple" :span="18">
			    <el-menu :router=true menu-trigger="click" active-text-color="#ff0000" :default-active="activeIndex"
			             mode="horizontal">
			      <el-menu-item index="/">首页</el-menu-item>
			      <el-menu-item index="/products">产品中心</el-menu-item>
			      <el-menu-item index="/about">关于我们</el-menu-item>
			
			    </el-menu>
			  </el-col>
			
			</el-row>
		</el-header>
	</div> -->
	
	
	<el-header class="me-area">
	  <el-row class="me-header">
	
	    <el-col :span="4" class="me-header-left">
	      <router-link to="/" class="me-title">
	        <img src="../assets/img/logo_head.png" style="padding-top: 3px;"/>
	        <!-- <img src="../static/img/logo.b3a48c0.png" /> -->
	      </router-link>
	    </el-col>
	
	    <el-col :span="16">
	      <el-menu :router=true menu-trigger="hover" active-text-color="#5FB878" :default-active="activeIndex"
	               mode="horizontal" style="margin-left: 25%; width: 800px;">
	        <el-menu-item index="/">首页</el-menu-item>
	        <el-menu-item index="/products">产品中心</el-menu-item>
			<el-menu-item index="/flyPlatform">飞控平台</el-menu-item>
			<el-menu-item index="/flySystem">飞控系统</el-menu-item>	
			<el-menu-item index="/dataProcessing">智能数据处理</el-menu-item>	
			<el-menu-item index="/result">竞赛成果</el-menu-item>
	        <el-menu-item index="/about">关于我们</el-menu-item>
	      </el-menu>
	    </el-col>
	
	  </el-row>
	</el-header>
</template>

<script>
	import { ArrowDown } from '@element-plus/icons-vue'
	import { getCurrentInstance } from "vue";
	export default {
		name: "HeaderView",
		//props: ['user'],//定义一个对象
		data(){
			return{
				// User: {}
			}
		},
		created(){
			console.log(this.$PageId);//控制台可以看到输出了10
			// var UserStr = sessionStorage.getItem('user')
			// this.User = JSON.parse(UserStr)
		},
		methods:{
			toHome: function(){
				this.$router.replace('/home')
			},
			toProducts: function(){
				index = "/products"
				this.$router.replace('/products')
			},
			toAbout: function(){
				this.$router.replace('/about')
			}
		}
	}
</script>

<style>
	/* .button1 {
		border:0;
		width: 100px;
		font-size: 1.5em;
	    background-color: white; 
	    color: #838f8e; 
	    
	}
	.button1:hover{
		background-color: white;
		color: #5cfffa;
	}
	.button2 {
		border:0;
		width: 130px;
		font-size: 1.5em;
	    background-color: white; 
	    color: #838f8e; 
	    
	}
	.button2:hover{
		background-color: white;
		color: #5cfffa;
	}
	.me-title{
		height: 58px
	}
	.me-title img{
		height: 58px
	}
	
	.el-header {
	  position: fixed;
	  z-index: 1024;
	  min-width: 100%;
	  box-shadow: 0 2px 3px hsla(0, 0%, 7%, .1), 0 0 0 1px hsla(0, 0%, 7%, .1);
	} */
	
	.el-header {
	  position: fixed;
	  z-index: 1024;
	  min-width: 100vw;
	  width: 100%;
	  box-shadow: 0 2px 3px hsla(0, 0%, 7%, .1), 0 0 0 1px hsla(0, 0%, 7%, .1);
	  background-color:white;
	}
	
	.me-title {
	  
	  font-size: 24px;
	}
	
	.me-header {
	  margin-left: 18%;
	}
	
	.me-title img {
	  max-height: 58px;
	  max-width: 100%;
	}
	
	.me-header-picture {
	  /* width: 36px;
	  height: 36px; */
	  border: 1px solid #ddd;
	  border-radius: 50%;
	  vertical-align: middle;
	  background-color: #5fb878;
	}
	
</style>