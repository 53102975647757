import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import HomeView from '../views/HomeView.vue'
import ProductsPage from '../views/ProductsPage.vue'
import AboutPage from '../views/AboutPage.vue'
import Layout from '../layout/layout.vue'

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
	redirect: "/home",
	children: [{
		path: 'home',
		name: 'HomePage',
		component: () => import('@/views/HomePage')
	},
	{
		path: '/products',
		name: 'ProductsPage',
		component: () => import('../views/ProductsPage.vue')
	},
	{
		path: '/about',
		name: 'AboutPage',
		component: () => import('@/views/AboutPage.vue')
	},
	{
		  path: '/product',
		  name: 'ProductMain',
		  component: () => import('@/views/ProductMain')
	},
	{
		  path: '/flyPlatform',
		  name: 'flyPlatform',
		  component: () => import('@/views/flyPlatformPage')
	},
	{
		  path: '/flySystem',
		  name: 'flySystem',
		  component: () => import('@/views/flySystemPage')
	},
	{
		  path: '/dataProcessing',
		  name: 'dataProcessing',
		  component: () => import('@/views/dataProcessingPage')
	},
	{
		  path: '/result',
		  name: 'result',
		  component: () => import('@/views/result')
	},
	
	]
  },
  
  
] 

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to,from,savedPosition){
  	if(savedPosition){
  		return savedPosition
  	}else{
  		return {x:0,y:0}
  	}
  }
})



export default router
