<template>
	<div style="height: 100%; background-color: #f7f7f7;">
		<div style="background-color: #4a4a4a;">
			<!-- <el-carousel :height="dataHeight" height="60%" interval="4000" type="card" indicator-position="outside" arrow="always" @change="change" style="min-width: 100vw;width: 100%;">-->
			<el-carousel height="70vh" interval="4000" type="card" indicator-position="outside" arrow="always" @change="change" style="min-width: 100vw;width: 100%;">
			    <el-carousel-item v-for="item in imgList" :key="item.name" style="box-shadow: 0 0 20px #f4f7f7;">
					<img :src="item.src" alt="image lost..." style="height:100%;width:100%;"/>
			    </el-carousel-item>
			</el-carousel>
		</div>
		
		<!-- <div style="width: 60%; margin: auto; background-color: #f7f7f7;">
			<h1 style="font-size: 5ch;font-style: oblique;">智茂电子科技</h1>
			<p style="text-indent: 45px; font-size: 2.5ch;letter-spacing:4px;margin-left: 0;">河南智茂电子科技有限公司是郑州市科技型企业，公司位于郑州市高新区企业公园，专注于复合翼、旋翼无人机研发、生产、销售及服务，致力于为客户提供智能化、平台化、工具化的无人机系统。</p>
			<p style="text-indent: 45px; font-size: 2.5ch;letter-spacing:4px;margin-left: 0;">技术方向：智能控制、AI智能识别、计算机视觉、定位与导航。</p>
		</div> -->
		<br>
		<div style="width: 100%; margin: auto; background-color: white;">
			<div style="width: 65%; margin: auto; display: flex;">
				<img src="../assets/img/fc01.jpg" style="width:50%; display: block; margin-left: 0%;" alt="img lost...">
				<hr style="background-color:#4a4a4a">
				<img src="../assets/img/fc02.jpg" style=" width:50%; display: block; margin-right: 0%;" alt="img lost...">
			</div>
			<div style="width: 65%; margin: auto; display: flex;">
				<img src="../assets/img/fc03.jpg" style="width:50%; display: block; margin-left: 0%;" alt="img lost...">
				<hr style="background-color:#4a4a4a">
				<img src="../assets/img/fc4.jpg" style="width:50%; display: block; margin-right: 0%;" alt="img lost...">
			</div>
		</div>
		
		<div style="width: 65%; margin: auto; ">
			<br>
			<!-- <p>培训</p>
			<p>
				......................................<br>
				......................................<br>
			</p> -->
			<div style="display: flex; height:400px;">
				<div id="app" style="width: 50%;margin-left: 0%;">
					<el-carousel height="400px">
					      <el-carousel-item v-for="item in imgList_1" :key="item.name" style="box-shadow: 0 0 20px #f4f7f7;">
					      	<img :src="item.src" alt="image lost..." style="height:100%;width:100%;"/>
							
					      </el-carousel-item>
					</el-carousel>
				</div>
				<div style="width: 50%;margin-right: 0%;">
					<div style="height: 33%; border: 3px solid white">
						<p style="margin-left: 10%;margin-top: 8%;font-size: 25px;"><el-icon><SuccessFilled /></el-icon> 河南省大学生电子设计竞赛筹备会</p>
					</div>
					<div style="height: 33%; border: 3px solid white">
						<p style="margin-left: 10%;margin-top: 8%;font-size: 25px;"><el-icon><SuccessFilled /></el-icon> 高校电子类专业学生认识实习</p>
					</div>
					<div style="height: 34%; border: 3px solid white">
						<p style="margin-left: 10%;margin-top: 8%;font-size: 25px;"><el-icon><SuccessFilled /></el-icon> 河南省电赛指导教师能力提升培训</p>
					</div>
				</div>
			</div>
			
		</div>
		<br>
		
	</div>
	
</template>

<script>
	
	// import request from "@/utils/request"
	export default {
		name: "HomePage",
		props: {
			dataHeight: {
				type: String,
				default: '855px'
			}
		},
		data(){
			return{
				
				// 产品图片
				imgList: [
				        {
				          name: "lj",
				          src: require("../assets/img/f1.jpg"),
				          title: "这是f1.png"
				        },
						{
						  name: "lj",
						  src: require("../assets/img/f02.jpg"),
						  title: "这是flyAi.png"
						},
				        {
				          name: "2j",
				          src: require("../assets/img/f03.jpg"),
				          title: "这是flyEye.png"
				        },
				        {
				          name: "3j",
				          src: require("../assets/img/f04.jpg"),
				          title: "这是plane.png"
				        },
						{
						  name: "3j",
						  src: require("../assets/img/f05.jpg"),
						  title: "这是plane.png"
						},
						{
						  name: "3j",
						  src: require("../assets/img/f06.jpg"),
						  title: "这是plane.png"
						}
				      ],
				imgList_1: [
						{
						  name: "lj",
						  src: require("../assets/img/px0.jpg"),
						  title: "这是f1.png"
						},
				        {
				          name: "lj",
				          src: require("../assets/img/px1.jpg"),
				          title: "这是f1.png"
				        },
				        {
				          name: "lj",
				          src: require("../assets/img/px2.jpg"),
				          title: "这是flyAi.png"
				        },
				        {
				          name: "2j",
				          src: require("../assets/img/px3.jpg"),
				          title: "这是flyEye.png"
				        },
				        {
				          name: "3j",
				          src: require("../assets/img/px4.jpg"),
				          title: "这是plane.png"
				        },
						{
						  name: "3j",
						  src: require("../assets/img/px5.jpg"),
						  title: "这是plane.png"
						}
				               
				      ],
				
			}
		},
		created(){
			// var UserStr = sessionStorage.getItem('user')
			// this.User = JSON.parse(UserStr)
			
		},
		methods:{
			moreP: function(){
				// index="/products"
				this.$router.replace('/products')
			},
			moreL: function(){
				// index = "/about"
				this.$router.replace('/about')
			},
			    
		}
	}
</script>

<style>
	/* .el-carousel__item h2 {
	  display: flex;
	  color: #475669;
	  opacity: 0.75;
	  line-height: 300px;
	  margin: 0;
	}
	
	.el-carousel__item:nth-child(2n) {
	  background-color: #99a9bf;
	}
	
	.el-carousel__item:nth-child(2n + 1) {
	  background-color: #d3dce6;
	} */
	/* .p1 {
		
	}
	.intro{
		margin:auto;
		text-align:center;
		
	}
	.picture_display{
		display:flex;
		justify-content: center; 
		align-items:center; 
		text-align:center;
		line-height:100px
	} */
	
</style>