<template>
	
  <div>
	  <router-view />
  </div>
  
</template>

<style>
</style>

<script>
export default {
  name: "App",
  components: {
    
  },
  watch: {
	  '$route': (to,from) => {
		  document.body.scrollTop = 0
		  document.documentElement.scrollTop = 0
	  }
  }
}
</script>
